// import Swiper JS
import Swiper from 'swiper';
import { EffectFade, Autoplay, Pagination } from 'swiper/modules';

import { debounce } from 'underscore';

import 'remodal/dist/remodal';

// import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-fade.min.css';
import 'swiper/modules/pagination.min.css';

let servicesPosition = {
  start: document.querySelector('.services').offsetTop - 100,
  end: document.querySelector('.services').offsetTop + document.querySelector('.services').clientHeight,
};

let teamPosition = {
  start: document.querySelector('#team').offsetTop,
  end: document.querySelector('#team').offsetTop + document.querySelector('#team').clientHeight,
};

let approachPosition = {
  start: document.querySelector('#approach').offsetTop,
  end: document.querySelector('#approach').offsetTop + document.querySelector('#approach').clientHeight,
};

let principlesPosition = {
  start: document.querySelector('#principles').offsetTop,
  end: document.querySelector('#principles').offsetTop + document.querySelector('#principles').clientHeight,
};

let securityPosition = {
  start: document.querySelector('#security').offsetTop,
  end: document.querySelector('#security').offsetTop + document.querySelector('#security').clientHeight,
};

new Swiper('#review_slider', {
  // Optional parameters
  autoplay: {
    delay: 5000,
  },
  modules: [Autoplay, EffectFade],
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  loop: true,
});

new Swiper('#team__list_slider', {
  // Optional parameters
  slidesPerView: 1,
  spaceBetween: 40,
  modules: [Pagination],
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    480: {
      slidesPerView: 'auto',
      spaceBetween: 80,
    },
    1140: {
      slidesPerView: 3,
      spaceBetween: 110,
    },
    // 768: {
    //   slidesPerView: 4,
    //   spaceBetween: 40,
    // },
    // 1024: {
    //   slidesPerView: 5,
    //   spaceBetween: 50,
    // },
  },

  // loop: true,
});

const handleResize = () => {
  servicesPosition = {
    start: document.querySelector('.services').offsetTop - 100,
    end: document.querySelector('.services').offsetTop + document.querySelector('.services').clientHeight,
  };

  teamPosition = {
    start: document.querySelector('#team').offsetTop,
    end: document.querySelector('#team').offsetTop + document.querySelector('#team').clientHeight,
  };

  approachPosition = {
    start: document.querySelector('#approach').offsetTop,
    end: document.querySelector('#approach').offsetTop + document.querySelector('#approach').clientHeight,
  };

  principlesPosition = {
    start: document.querySelector('#principles').offsetTop,
    end: document.querySelector('#principles').offsetTop + document.querySelector('#principles').clientHeight,
  };

  securityPosition = {
    start: document.querySelector('#security').offsetTop,
    end: document.querySelector('#security').offsetTop + document.querySelector('#security').clientHeight,
  };
};

document.querySelectorAll('#approach__list > section').forEach((el) => {
  el.addEventListener('click', (e) => {
    document.querySelector('#approach__list > section.expanded').classList.remove('expanded');
    e.currentTarget.classList.add('expanded');
  });
});

document.querySelectorAll('#nav > a, .header__logo a').forEach((el) => {
  el.addEventListener('click', (e) => {
    if (document.querySelector('header.active_menu')) document.querySelector('header.active_menu').classList.remove('active_menu');
  });
});

const handleScroll = () => {
  const y = window.scrollY;

  if (y == 0) {
    document.querySelector('header').classList.add('transparent');
  } else {
    document.querySelector('header').classList.remove('transparent');
  }

  // Current menu item
  if (y >= servicesPosition.start && y < servicesPosition.end) {
    if (!document.querySelector('[data-name="services"].active')) {
      document.querySelectorAll('#nav a').forEach((el) => {
        el.classList.remove('active');
      });
      document.querySelector('[data-name="services"]').classList.add('active');
    }
  } else if (y >= teamPosition.start && y < teamPosition.end) {
    if (!document.querySelector('[data-name="team"].active')) {
      document.querySelectorAll('#nav a').forEach((el) => {
        el.classList.remove('active');
      });
      document.querySelector('[data-name="team"]').classList.add('active');
    }
  } else if (y >= approachPosition.start && y < approachPosition.end) {
    if (!document.querySelector('[data-name="approach"].active')) {
      document.querySelectorAll('#nav a').forEach((el) => {
        el.classList.remove('active');
      });
      document.querySelector('[data-name="approach"]').classList.add('active');
    }
  } else if (y >= principlesPosition.start && y < principlesPosition.end) {
    if (!document.querySelector('[data-name="principles"].active')) {
      document.querySelectorAll('#nav a').forEach((el) => {
        el.classList.remove('active');
      });
      document.querySelector('[data-name="principles"]').classList.add('active');
    }
  } else {
    document.querySelectorAll('#nav a').forEach((el) => {
      el.classList.remove('active');
    });
  }

  if (
    (y >= teamPosition.start - 33 && y < teamPosition.end) ||
    (y >= principlesPosition.start - 33 && y < principlesPosition.end) ||
    (y >= securityPosition.start - 33 && y < securityPosition.end)
  ) {
    if (!document.querySelector('header.revert')) {
      document.querySelector('header').classList.add('revert');
    }
  } else {
    if (document.querySelector('header.revert')) {
      document.querySelector('header').classList.remove('revert');
    }
  }
};

document.querySelector('#mobile_menu_button').addEventListener('click', () => {
  document.querySelector('header').classList.toggle('active_menu');
});

document.addEventListener('scroll', handleScroll);
window.addEventListener('resize', debounce(handleResize, 300));
